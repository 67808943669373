<template>
  <div class="table table-hover mb-0" id="table-objusers">
   
        <template v-for = "( user, key  ) in sortedusers" >

        <template v-if="user.type=='spacer'">
          <h4 :key="'header'+key" class="mt-3 d-flex align-items-center" @click.stop.prevent="showsystemlist=showsystemlist?false:true">
            <a href="#"><i style="font-size: 16px;" v-if="!showsystemlist"  class="fa-regular fa-chevron-right p-2"></i>
            <i style="font-size: 16px;" v-else class="fa-regular fa-chevron-down p-2"></i>System teams</a>
          </h4>
        </template>

        
        <template v-else>
          <div :key="key" v-show="!systemgenerated(user) || ( systemgenerated(user) && showsystemlist ) ">
        <div class = "d-flex tablerow"  v-if="user.status && user.status.toUpperCase() != 'DELETED'"> 

          <div v-if="user.state!='editcontact'" class ="d-flex" nowrap> 

           <base-avatar :user="user" :class="'tablepic round mr-2 my-auto'" :id="'profilepic'" > </base-avatar>


             <div class="my-auto mr-2 username" v-if="user.expandable == true">

              

               <span class="grouptext" :style="listtype=='listofteams' ? 'width: 300px;' : ( user.role.toLowerCase() == 'contributor' ? 'width: 160px;' : 'width: 170px;' )">
                  
                  <a href="#" @click.prevent="emitsomthing('onexpandme', user, $event, false)" v-if="user.subitems && user.subitems.length > 0" style="line-height:12px">
                  <span>{{username(user)}}</span> 
                  </a> 
                  <span v-else style="line-height:12px">{{username(user)}}</span> 

              </span>

             </div>
             <div class="my-auto mr-2 username" v-else> <span class="grouptext "> <span>{{username(user)}}</span> <span v-if="isboardusers(user._id)" class="badge badge-light">Member</span>  <span v-if="( user.orgrole && user.orgrole.toUpperCase() == 'DISABLE') || ( user.role && user.role.toUpperCase() == 'DISABLE') && ( user.orgstatus && user.orgstatus.toUpperCase() != 'INACTIVE' )" class="mt-1 badge badge-light ">No access</span>   <span v-else-if="user.orgstatus && user.orgstatus.toUpperCase()=='INACTIVE'" class="mt-1 badge badge-light ">Inactive</span> </span> 
            
                          

            </div>           
          </div> 

          <div class="w-100" v-if="user.state=='editcontact'"> 

            <div class="mb-2">
              <input type="text" class="form-control form-control-sm" placeholder="Name" v-model="userfirstname" maxlength="70">
              <span class="text-danger" v-if="user.errormessage && user.errormessage == 'nameinvalid'"><small>Name is required</small></span>
            </div>
            <div class="mb-2">
              
              <emailField @onworking="emailworking" @onfail="emailfieldfail" @onsuggest="suggestuser" @oncancel="cancelsuggestion" @oninput="updateExternalEmail" :emailvalue="userexternalemail" :namevalue="userfirstname" :users="users" ></emailField>

              <input v-if="1==2" type="text" class="form-control form-control-sm" placeholder="Email" v-model="userexternalemail" maxlength="70">
              <span class="text-danger" v-if="user.errormessage && user.errormessage == 'emailinvalid'"><small>Email is invalid</small></span>
            </div>
            <div class="mb-2 text-right">
              <button class="btn btn-secondary" @click.stop.prevent="user.state='idle';cancelupdatefields()">Cancel</button> <button class="btn btn-primary" @click.stop.prevent="fieldsupdate( user );emitsomthing('onuserinteraction', 'savecontact', user, { state:'idle', users:users })" v-if="userfirstname != '' && emailfieldfailed == false && emailisworking==false">Save</button>
              <button class="btn btn-primary" type="button" v-if="userfirstname == '' || emailfieldfailed || emailisworking==true " disabled="disabled">Save</button>
            </div>
          </div>

         <template v-if="listtype == 'membersperteam' || listtype == 'listofteams' || listtype == 'stakeholderscontacts'" >
          

          <div nowrap class=" actions d-flex ml-auto" v-if="(listtype == 'stakeholderscontacts' && user.state != 'editcontact') && canwrite" >

            <div class="dropdown membersdropdown">
             <button :class="{'disabled':isworking}" class="gg mebmersactionsbutton btn dd-text user_role text-right" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :disabled="user.role == adminrole && numowners <= 1"> 
              <span><i class="fa fa-bars text-primary2"></i> </span>
            </button>
            <div class="p-0 dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton"  >
                <a v-if="user.status=='stakeholder'" class="dropdown-item" href="#" @click.stop.prevent="emitsomthing('onuserinteraction', 'switchstate', user, { state:'editcontact' , users:users });updatefields( user )">Edit</a>
                <a v-if="user.status=='stakeholder' && ( user.externalemail && !user.details ) || ( user.status!='stakeholder' && !user.details && !isboardusers(user._id))" class="dropdown-item" href="#" @click.stop.prevent="emitsomthing('onuserinteraction', 'invitecontact', user, { userid: user._id })">Invite user to board</a>
                <a v-if="(user.status=='stakeholder' && user.details) || (user.status!='stakeholder' && !isboardusers(user._id))" class="dropdown-item" href="#" @click.stop.prevent="emitsomthing('onuserinteraction', 'replacecontact', user, { userid: user.details._id })">Add user to board</a>
                <a v-if="isboardusers(user._id)" class="dropdown-item" href="#" @click.stop.prevent="emitsomthing('onuserinteraction', 'removefromboard', user, { userid: user.details._id })">Remove user from board</a>
                <div v-if="user.status=='stakeholder'"  class="dropdown-divider m-0" ></div>
                <a class="dropdown-item actiondelete" @click.stop.prevent="emitsomthing('onuserinteraction', 'removecontact', user, { userid: user._id} )"> Remove</a>
            </div>
            </div>

         </div>

          <div nowrap class=" actions d-flex ml-auto" v-if="listtype == 'membersperteam'" >

              <div class="dropdown membersdropdown">
               <button :class="{'disabled':isworking}" class="gg mebmersactionsbutton btn dropdown-toggle dd-text user_role btn-primary text-right" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :disabled="user.role == adminrole && numowners <= 1">
                <span>{{ user.status == 'invited' ? 'Invited' : ( user.role == adminrole ? 'Owner' : 'Member' )  }}</span>
              </button>
              <div class="p-0 dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                  <a v-if="user.status!='invited'" class="dropdown-item" :class="{disabled:user.role == 'Owner'}"  href="#" @click.prevent="emitsomthing('onswitchrole', user, {team:team,role:'Owner'} , false)">Owner</a>
                  <a v-if="user.status!='invited'" class="dropdown-item" :class="{disabled:user.role == 'Member'}" href="#" @click.prevent="emitsomthing('onswitchrole', user, {team:team,role:'Member'}, false)">Member</a>
                  <a v-if="user.status=='invited'" href="#" class=" dropdown-item action " v-on:click.prevent.stop="emitsomthing( 'oninviteuser', true, user)">Resend invite</a>
                  <div  class="dropdown-divider m-0" ></div>
                  <a class="dropdown-item actiondelete"  href="#" @click.prevent="emitsomthing('onremovefromteam', user, team, false)">Remove from team</a>
              </div>
              </div>

         </div>


          <div nowrap class=" actions d-flex ml-auto" v-if="listtype == 'listofteams'" >

                    <base-icon-label v-if="user.myrole==adminrole" class ="menu-icon"  iconSize ="sm" @click.prevent.stop="teamsetting(user._id)" icon ="fal fa-cog"  labelafter =""/>

         </div>


         

         </template>

         <template v-else>
         <div nowrap class=" actions d-flex ml-auto" v-if ="( myrole.toUpperCase() == adminrole.toUpperCase() || listtype == 'organizationmembers' ) && isactive">

          <div class=" actions my-auto text-right" v-if="isadmanaged">            
            <div class="dropdown membersdropdown" v-if="user.role.toUpperCase()!='DISABLE'">
                  <button :class="{'disabled':isworking}" class="hh mebmersactionsbutton btn dropdown-toggle dd-text  user_role btn-primary text-right" :disabled="user.role == adminrole && numowners <= 1" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <template v-for="( role, index) in roles" >
                      <span :key ="index" v-if="role.value && user.role.toUpperCase() == role.value.toUpperCase()">{{ role.label }}</span>
                    </template>

                  </button>
                  <div class="p-0 dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                    <template v-for="( role, index) in roles">
                      <div v-if="role.label == ''" :key ="index" class="dropdown-divider m-0" ></div>
                      <a :class="{'disabled': user.role.toUpperCase() == role.value.toUpperCase(), 'actiondelete' : role.value == 'Disable'  }" v-if="role.label != '' && ( isadmanaged && role.admananged )" class="dropdown-item"  :key ="index" href="#" @click.prevent="emitsomthing('switchrole', user, role.value, false)">{{role.label}}</a>
                    </template>
                  </div>
 
                  
                </div>
          </div> 
          <div class=" actions my-auto text-right" v-if="isadmanaged==false">
                <!-- <span v-if="user.status=='pendingjoin' && user.status !='deleting'" class="badge badge-light">Awaiting approval</span>
                  <span  && user.status !='deleting'" class="badge badge-light">Pending - invite sent</span>  -->
                  <span v-if="user.status=='pendingjoin'">
                    <div class="dropdown membersdropdown">

                      <button :class="{'disabled':isworking}" class="btn dropdown-toggle dd-text user_role btn-primary text-right" :disabled="user.role == adminrole && numowners <= 1" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >For approval</button>

                      <div class="p-0 dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton"  >
                       <a class=" dropdown-item action" href="#" v-on:click.prevent.stop="emitsomthing('accessrequest', user, true, $event )">Approve</a> 
                        <div class="dropdown-divider m-0"></div>
                       <a class="dropdown-item actiondelete" href="#" v-on:click.prevent.stop="emitsomthing('accessrequest',  user, false, $event )">Reject</a> 
                     </div>
                   </div>       

                 </span>

                 <span v-if="(user.status && user.status.toUpperCase() == 'INVITED' && user.role && user.role.toUpperCase() != 'DISABLE' ) || ( user.role && user.role.toUpperCase() == 'INVITED' )">
                  <div class="dropdown membersdropdown" id="">
                    <button :class="{'disabled':(isworking || !isadmin)}" class="aa mebmersactionsbutton btn dropdown-toggle dd-text user_role btn-primary text-right" :disabled="user.role == adminrole && numowners <= 1" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >Invited</button>

                    <div class="p-0 dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" v-if="adminrole.toUpperCase() != 'ADMINISTRATOR'"  >
                     <a href="#" class=" dropdown-item action " v-on:click.prevent.stop="emitsomthing( 'resendemail', user, 'confirm', false)">Resend invite</a>
                     <div class="dropdown-divider m-0"></div>
                     <a href="#" class=" dropdown-item actiondelete " v-on:click.prevent.stop="emitsomthing( 'tryremoveuser', user, 'confirm', false)" v-if="( (user.role == adminrole && numowners > 1) || user.role != adminrole) " >Remove</a>
                   </div>


                   <div class="p-0 dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" v-if="listtype=='organizationmembers'" >
                     <a href="#" class=" dropdown-item action " v-on:click.prevent.stop="emitsomthing( 'resendemail', user, 'confirm', false)" v-if="myrole.toUpperCase() == 'ADMINISTRATOR'">Resend invite</a>
                      <div class="dropdown-divider m-0" v-if="myrole.toUpperCase() == 'ADMINISTRATOR'"></div> 
                     <a v-if="user.status.toUpperCase() == 'INVITED' || user.orgstatus.toUpperCase() == 'INVITED'" href="#" class=" dropdown-item actiondelete " @click.prevent="emitsomthing('switchrole', user, 'Disable', false)">Revoke access</a>
                   </div>
                 </div>      
               </span>

               <span v-else-if="user.status.toUpperCase()=='INACTIVE'">
                 
                 <div class="dropdown membersdropdown" v-if="user.role && user.role.toUpperCase() != 'DISABLE'">
                  <button :class="{'disabled':isworking}" class="bb mebmersactionsbutton btn dropdown-toggle dd-text  user_role btn-primary text-right" :disabled="user.role == adminrole && numowners <= 1" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <template v-for="( role, index) in roles" >
                      <span :key ="index" v-if="role.value && user.role.toUpperCase() == role.value.toUpperCase()">{{ role.label }}</span>
                    </template>

                  </button>
                  <div class="p-0 dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                    <template v-for="( role, index) in roles">
                      <div v-if="role.label == ''" :key ="index" class="dropdown-divider m-0" ></div>
                      <a :class="{'disabled': user.role.toUpperCase() == role.value.toUpperCase(), 'actiondelete' : role.value == 'Disable'  }" v-if="role.label != ''" class="dropdown-item"  :key ="index" href="#" @click.prevent="emitsomthing('switchrole', user, role.value, false)">{{role.label}}</a>
                    </template>

                    <div class="dropdown-divider m-0" v-if ="hasremove"></div>
                    <a href="#" class=" dropdown-item actiondelete" v-on:click.prevent.stop="emitsomthing( 'tryremoveuser', user, 'confirm', false)" v-if="( ((user.role == adminrole && numowners > 1 ) || user.role != adminrole) && hasremove ) " >Remove</a>
                  </div>
 
                  
                </div>

                <div class="dropdown membersdropdown" v-if="user.role && user.role.toUpperCase() == 'DISABLE'">
                   <button :class="{'disabled':isworking}" class="cc mebmersactionsbutton btn dropdown-toggle dd-text user_role btn-primary text-right" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span>No access</span>
                  </button>
                  <div class="p-0 dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton"  >
                      <a class="dropdown-item actiondelete"  href="#" @click.prevent="emitsomthing('switchrole', user, 'Member', false)">Restore access</a>
                  </div>
                </div>

               </span>

               <span v-else>                 

                 <template v-if="user.role && user.role.toUpperCase() == 'DISABLE'">

                  <div class="dropdown membersdropdown">
                   <button :class="{'disabled':isworking}" class="dd mebmersactionsbutton btn dropdown-toggle dd-text user_role btn-primary text-right" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span>No access</span>
                  </button>
                  <div class="p-0 dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton"  >
                      <a v-if="user.orgstatus != 'invited'" class="dropdown-item actiondelete"  href="#" @click.prevent="emitsomthing('switchrole', user, 'Member', false)">Restore access</a>
                      <a v-if="user.orgstatus == 'invited'" class="dropdown-item actiondelete"  href="#" @click.prevent="emitsomthing('switchrole', user, 'invited', false)">Restore access</a>
                  </div>
                </div>

                 </template>
                 <template v-else>
                  <template v-if="listtype=='organizationmembers'">



                    <div class="dropdown membersdropdown" v-if="user.status !='pendingjoin' && user.status !='invited'">
                      <button :class="{'disabled':( isworking || !isadmin )}" class="ee0 mebmersactionsbutton btn dropdown-toggle dd-text  user_role btn-primary text-right" :disabled="user.role == adminrole && numowners <= 1 && ( user.usertype != 'Team' )" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <template v-for="( role, index) in roles" >                          

                          <span :key ="index" v-if="role.value && user.role.toUpperCase() == role.value.toUpperCase()"> {{ role.label }}</span>
                        </template>

                      </button>
                      <div class="p-0 dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                        <template v-for="( role, index) in roles">

                          <template v-if="role.foradmin == true">
                            
                            <template v-if="myrole.toUpperCase() == 'ADMINISTRATOR'">
                              <div v-if="role.label == ''" :key ="index" class="dropdown-divider m-0" ></div>
                              <a :class="{'disabled': user.role.toUpperCase() == role.value.toUpperCase(), 'actiondelete' : role.value == 'Disable'  }" v-if="role.label != ''" class="dropdown-item"  :key ="index" href="#" @click.prevent="emitsomthing('switchrole', user, role.value, false)">{{role.label}} </a>
                            </template>
                            

                          </template>
                          <template v-else>

                            <div v-if="role.label == ''" :key ="index" class="dropdown-divider m-0" ></div>
                            <a :class="{'disabled': user.role.toUpperCase() == role.value.toUpperCase(), 'actiondelete' : role.value == 'Disable'  }" v-if="role.label != ''" class="dropdown-item"  :key ="index" href="#" @click.prevent="emitsomthing('switchrole', user, role.value, false)">{{role.label}} </a>
                            
                          </template>

                          
                        </template>

                        <div class="dropdown-divider m-0" v-if ="hasremove"></div>
                        <a v-if ="hasremove" href="#" class=" dropdown-item actiondelete" v-on:click.prevent.stop="emitsomthing( 'tryremoveuser', user, 'confirm', false)" >Remove</a>
                      </div>
                    </div>

                  </template>
                  <template v-else>
                    <div class="dropdown membersdropdown" v-if="user.status !='pendingjoin' && user.status !='invited'">
                      <button :class="{'disabled':isworking}" class="ee mebmersactionsbutton btn dropdown-toggle dd-text  user_role btn-primary text-right" :disabled="user.role == adminrole && numowners <= 1" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <template v-if="user.type == 'everyone'">
                          <span> Viewer </span>
                        </template>
                        <template v-else >
                          <template v-for="( role, index) in roles" >
                          <span :key ="index" v-if="role.value && user.role.toUpperCase() == role.value.toUpperCase()"> {{ role.label }}</span>
                          </template>
                        </template>

                        

                      </button>
                      <div class="p-0 dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                        <template v-for="( role, index) in roles">
                          <div v-if="role.label == ''" :key ="index" class="dropdown-divider m-0" ></div>
                          <a :class="{'disabled': user.role.toUpperCase() == role.value.toUpperCase(), 'actiondelete' : role.value == 'Disable'  }" v-if="role.label != ''" class="dropdown-item"  :key ="index" href="#" @click.prevent="emitsomthing('switchrole', user, role.value, false)">{{role.label}} </a>
                        </template>

                        <div class="dropdown-divider m-0" v-if ="hasremove"></div>
                        <a v-if ="hasremove" href="#" class=" dropdown-item actiondelete" v-on:click.prevent.stop="emitsomthing( 'tryremoveuser', user, 'confirm', false)" >Remove</a>

                        <a v-if="user.sharedtousers && loggedinuser._id && isteamowner( user.sharedtousers, loggedinuser._id)" href="#" class=" dropdown-item" v-on:click.prevent.stop="teamadmin(user._id)" >Team administration</a>
                      </div>
                    </div>
                  </template>

                </template>

                </span>


              </div>
            </div> 
            <div v-else class = "ml-auto my-auto"> 
              <span class = "float-right" v-if="user.role && ( user.role.toUpperCase() == 'DISABLE' || user.status.toUpperCase() == 'INACTIVE') ">No access</span>
              <span class = "float-right" v-else-if="user.status.toUpperCase() == 'INVITED'">Invited</span>
              <span class = "float-right" v-else>{{user.role}}</span> 
            </div>

              </template>
            </div>

            <template v-for = "user2 in user.subitems" >
              <div class="ml-3 d-flex tablerow smaller" :key="user.name + '-' + user2._id" v-if="user.expandable && user.expanded"> 

                <div class ="d-flex " nowrap>
                 <base-avatar :user="user2" :class="'tablepic round mr-2 my-auto'" :id="'profilepic'" > </base-avatar>
                 
                   <div class="my-auto mr-2 username2"> <span class="grouptext "> <span>{{username(user2)}}</span> 

                    <span v-if="user2.orgrole && user2.orgrole.toUpperCase()=='DISABLE'" class="mt-1 badge badge-light ">No access</span>
                    <span v-if="user2.role.toUpperCase() == adminrole.toUpperCase() && listtype != 'inboards'" class="ml-2 mt-1 badge badge-light">Owner</span>                   

                 </span> 

                  </div> 

                  
                </div>


                <div class = "ml-auto my-auto"> 

                    <div class="dropdown membersdropdown" v-if="readonlysubitems==false">
                     <button :class="{'disabled':isworking}" class="ff mebmersactionsbutton btn dropdown-toggle dd-text user_role btn-primary text-right" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span>{{user2.role == adminrole ? 'Owner' : 'Member' }}</span>
                    </button>
                    <div class="p-0 dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton"  >
                        <a class="dropdown-item"  href="#" @click.prevent="emitsomthing('onswitchrole', user2, {team:user,role:'Owner'} , false)">Owner</a>
                        <a class="dropdown-item"  href="#" @click.prevent="emitsomthing('onswitchrole', user2, {team:user,role:'Member'}, false)">Member</a>
                        <div  class="dropdown-divider m-0" ></div>
                        <a class="dropdown-item actiondelete"  href="#" @click.prevent="emitsomthing('onremovefromteam', user2, user, false)">Remove from team</a>
                    </div>
                    </div>
                        </div>

              </div>
            </template>

            </div>
           </template>
          </template>

          

        </div>
</template>

<style lang ="scss" scoped>

.text-primary2{
  color: #000000;
}

.btn:focus, .btn.focus {
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
  }

  @import '@/scss/abstracts/variable.scss';
  .username{
    /* word-wrap: break-word; 
    min-width:0px; */
  }

  .grouptext{
    display: block;
  }

  .smaller{
   /* zoom: 0.9;
    -moz-transform: scale(0.9);*/
    padding-left: 10px;
    
  }

  .smaller .username{
    font-size: 15px !important;
  }

  .smaller .tablepic{
    width: 28px !important;
    height: 28px !important;
  }

</style>

<script type="text/javascript"></script>

<script>

  import emailField from '@/components/fields/emailField.vue'

  export default{
    name:'memberslist',
    data(){
      return{
        userfound:false,
        userfirstname:"",
        userfirstnametemp:"",
        userexternalemail:"",
        emailfieldfailed:false,
        emailisworking:false,
        showsystemlist:false
      }
    },
    props:{
      boardusers:{
        type: Array,
        default: () => []
      },    
      users:{
        type: Array,
        default: () => []
      },      
      readonlysubitems:{
        type:Boolean,
        default: true
      },
      includeteams:{
        type:Boolean,
        default: true
      },
      isadmanaged:{
        type:Boolean,
        default: true
      },
      canwrite:{
        type:Boolean,
        default: false
      },
      hasremove:{
        type:Boolean,
        default: true
      },
      showsystem:{
        type:Boolean,
        default: true
      },
      listtype:{
        type:String,
        default: 'none'
      },
      myrole:{
        type:String,
        default: 'Viewer'
      },
      roles:{
        type: Array,
        default: () => []
      },
      numowners: { 
        type: Number,
        default:1,
      },
      isactive:{
        type: Boolean,
        default: false
      },
      adminrole:{
        type: String,
        default: 'Owner'
      },
      team:{
        type: Object,
          default: () => {}
      },
      username:{
        type:Function,
        default: (user) =>{
          return user
        }
      },
      isworking:{
          type:Boolean,
          default: false
        }
    },
    watch:{

      /*users : function(){

        alert('')
      }*/
    },
    computed: {

      sortedusers(){

        let self = this
        let users = this.users
        let realusers = []
        let systemteams = []

        users.forEach( function( user ){

          if( self.systemgenerated(user) ){
            systemteams.push(user)
          }else{
            realusers.push(user)
          }

        })

        let newusers = []

        if( systemteams.length > 0 ){

          let spacer = {type:"spacer"}
          realusers.push( spacer )
          newusers = realusers.concat( systemteams )

        }else{
          newusers = realusers
        }

        return newusers
      },

      isadmin(){


        let toreturn = false

        if( this.adminrole.toUpperCase() == this.myrole.toUpperCase() ){
          toreturn = true
        }

        //a{{ myrole }}a a{{ adminrole }}a  

        return toreturn
      },



        cockpitid(){
            return this.$route.params.cid
        },


      orgkey(){
          return this.$route.params.orgname ? this.$route.params.orgname : null
        },

     /* isteam( user ){
        return user.usertype == "Team"
      },*/

      token(){
        return this.$store.getters.token
      },



      btndisabled(){
        return !this.isworking
      },


      loggedinuser(){
        return this.$store.getters.user
      },
     
    },
    mounted(){
     


       this.$nextTick(function(){
          //geting the dropdown the user just oppen 
          $('.membersdropdown').on('show.bs.dropdown', function(){
            //check if the dropdown goes outside the modale
            if($(this.childNodes[1]).height() + this.offsetTop + this.clientHeight > $('.modal-body').height() ){
              //if ture expaing the model so the dropdoewn fits
              $('.modal-body').height($(this.childNodes[1]).height() + this.offsetTop + this.clientHeight + 10 + 'px')
            }
          })
          $('.membersdropdown').on('hide.bs.dropdown', function(){
            //seting the height of the modal back to autp
            $('.modal-body').height('auto')
          })
        })
    },
    methods: {

      emailworking( value ){
        this.emailisworking = value
      },

      isteamowner( teamusers, userid ){



        let found = 0

        teamusers.forEach(function (element) {
          //console.log( element.userid, userid, element.role, "teamusers")
                    if( element.userid == userid && element.role == "Owner" ){
                      found += 1
                    }
                  });

        //let obj = teamusers.find(o => o.userid === userid);        
        return found > 0 ? true : false //obj ? true : false

      },

      teamadmin( teamid ){

         let prevpath = this.$route.fullPath

         this.$router.push('/'+ this.orgkey+'/organizations/teams/'+teamid+'?history='+prevpath )
      },

      emailfieldfail( isfailed ){

        this.emailfieldfailed = isfailed

      },

      updateExternalEmail( newvalue ){

        this.userexternalemail = newvalue
      },

      cancelsuggestion(){

        if( this.userfound == true ){

          this.userfirstname = this.userfirstnametemp
          this.userfirstnametemp = ""
          this.userfound = false

        }
        
      },

      suggestuser( user ){

        if( this.userfirstname != user.firstname + " " + user.lastname ){

          this.userfirstnametemp = this.userfirstname
          this.userfirstname = user.firstname + " " + user.lastname
          this.userfound = true 

        }else{

          this.userfirstnametemp = ""
          this.userfound = false
        }



      },

      systemgenerated( object ){

        let toreturn = false
        if( object.measuretype && ( object.measuretype == "risk" || object.measuretype == "masterplan") ){
          toreturn = true
        }

        if( object.type && object.type == "stakeholder" ){
          toreturn = true
        }


        if( object.type && object.type == "risk" ){
          toreturn = true
        }

        if( object.type && object.type == "masterplan" ){
          toreturn = true
        }

        if( object.type && object.type == "everyone" ){
          toreturn = true
        }

        

        return toreturn

      }, 

      cancelupdatefields(){

       // alert('y')
      },

      fieldsupdate( user ){

        user.firstname = this.userfirstname
        user.externalemail = this.userexternalemail
      },

      updatefields( user ){

        this.userfirstname = user.firstname
        this.userexternalemail = user.externalemail

      },

      isboardusers( userid ){

        let toreturn = false

        if( this.boardusers ){

          if( this.boardusers.indexOf( userid ) > -1){
            toreturn = true
          }

        }

        return toreturn
      },

      teamsetting( teamid ){

        //location.href='/'+ this.orgkey+'/organizations/teams/'+teamid

        this.$router.push('/'+ this.orgkey+'/organizations/teams/'+teamid)
                
      },
      
      emitsomthing(callname, user,state, evnet = {}){

        var isteam = false

        if( user.email && user.email.toUpperCase() == "TEAM" && callname == 'tryremoveuser' ){
          isteam = true
        }


        if( callname == 'switchrole' ){
          //user.status = "working|"+user.status
          //user.role = state
          //if( user.orgrole == "Disable" ){
            //user.orgrole = ""
          //}
        }

        $('.dropdown-menu.show').removeClass('show')
       // console.log( callname, user, state, evnet, isteam )
        this.$emit(callname, user, state, evnet, isteam )


      }
    },
    components : { emailField }
  }
</script>



